import React from "react";
import HomePage from "../homePageComponents/HomePage";
import DoctorDashboard from "../AllDashboardPages/doctorDashboardPages/doctorDashboard/DoctorDashboard";
import TeacherDashboard from "../AllDashboardPages/teacherDashboardPages/teacherDashboard/TeacherDashboard";
import AdvocateDashboard from "../AllDashboardPages/advocateDashboardPages/advocateDashboard/AdvocateDashboard";

function DashboardProtection({}) {
  const vendorType = localStorage.getItem("vendorType");
  console.log(vendorType);

  if (vendorType === "66b311580a59deec38b59574") {
    return <DoctorDashboard />;
  } else if (vendorType === "66b311ac0a59deec38b5957d") {
    return <TeacherDashboard />;
  } else if (vendorType === "66b311960a59deec38b5957a") {
    return <AdvocateDashboard />;
  } else {
    return <HomePage />;
  }
}

export default DashboardProtection;
